import { Button, Dialog } from 'evergreen-ui';
import React, { useEffect, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import styled from 'styled-components';

export const ProductDetails = ({ isShown, setIsShown, productInfo }: any) => {

    const [images, setImages] = useState<any>();


    useEffect(() => {
        let img: any[] = [];
        if (productInfo) {

            productInfo.images.forEach((image: string) => {
                const item: ReactImageGalleryItem = {
                    original: image,
                    thumbnail: image,
                    thumbnailHeight: 10
                }
                img.push(item);
            });
            setImages(img);
        }
    }, [productInfo])

    return (
        <>
            <Dialog
                isShown={isShown}
                title='Product Details'
                minHeightContent={0}
                width='600px'
                intent='danger'
                confirmLabel='Delete'
                hasHeader={false}
                hasFooter={false}
                onCloseComplete={() => setIsShown(false)}
            >
                {({ close }) => (
                    <>
                        <div className='flex justify-between pt-3'>
                            <h3 className='text-blue-600 font-semibold'>{productInfo?.productName}</h3>
                            <Button appearance='minimal' onClick={close} ><GrClose /></Button>
                        </div>
                        <div className='grid grid-cols-2 gap-2'>
                            <GalleryContainer>
                                <ImageGallery autoPlay={true} items={images} showFullscreenButton={false} showPlayButton={false} showNav={false} />
                            </GalleryContainer>
                            <div className='px-5 font-sans'>
                                <div><strong>Product Code:</strong> {productInfo.productCode}</div>
                                <div><strong>Description:</strong> {productInfo.description}</div>
                                <div><strong>Category:</strong> {productInfo.category}</div>
                                <div><strong>Unit Price:</strong> {productInfo.costPrice}</div>
                                <div><strong>Old Price:</strong> {productInfo.oldPrice}</div>
                                <div><strong>Selling Price:</strong> {productInfo.sellingPrice}</div>
                                <div><strong>Quantity:</strong> {productInfo.quantity}</div>
                                <div><strong>Status:</strong> {productInfo.status}</div>
                            </div>

                        </div>
                    </>
                )}
            </Dialog>
        </>
    )
}

const GalleryContainer = styled.div`
    .image-gallery {
        height: 300px;
        width: auto;
    }
    .image-gallery-thumbnail{
        height: 50px;
        width: 50px;
    }
`