import { createTheme, ThemeProvider } from '@mui/material';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactLoader from './components/Loader';
import { AuthProvider } from './helpers/AuthProvider';
import { Dashboard } from './pages/Dashboard';
import { Login } from './pages/Login';
import { Orders } from './pages/Orders';
import Products from './pages/Products';
import { GuardedRoutes } from './utils/GuardedRoutes';
import { PublicRoutes } from './utils/PublicRoutes';

const theme = createTheme({
    typography: {
        "fontFamily": `"Poppins"`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#3b82f6'
        },
        secondary: {
            main: '#1E3A8A'
        }
    }
});


const Home = lazy(() =>
    import('./pages/Home').then((module) => ({ default: module.Home }))
);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <AuthProvider>
                    <Suspense fallback={<ReactLoader />}>
                        <Routes>
                            <Route path='/' element={<GuardedRoutes />}>
                                <Route path='/' element={<Home />}>
                                    <Route path='/' element={<Dashboard />} />
                                    <Route path='/dashboard' element={<Dashboard />} />
                                    <Route path='/products' element={<Products />} />
                                    <Route path='/orders' element={<Orders />} />
                                </Route>
                            </Route>
                            <Route path='/login' element={<PublicRoutes />}>
                                <Route path='/login' element={<Login />} />
                            </Route>
                        </Routes>
                    </Suspense>
                </AuthProvider>
            </Router>
        </ThemeProvider >
    );
}

export default App;
