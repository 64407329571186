import { Combobox } from 'evergreen-ui';
import React from 'react';
import { BsFillExclamationCircleFill } from 'react-icons/bs';

export const DropDown = (props: any) => {
    return (
        <div className='mb-6 w-full'>
            <div className='mb-2 font-medium' style={{ color: '#101840', lineHeight: '18px', fontSize: '14px' }}>{props.label}</div>
            <Combobox {...props} />
            <div>
                {props.inputProps?.invalidMsg &&
                    <>
                        <BsFillExclamationCircleFill display='inline' color='#D14343' size='16px' style={{ marginRight: '8px', display: 'inline' }} />
                        <p style={{ color: '#D14343', lineHeight: '18px', fontSize: '12px', display: 'inline' }}>{props.errormsg}</p>
                    </>
                }
            </div>
        </div>
    )
}
