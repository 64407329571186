import { Button } from '@mui/material';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import { HiOutlineDocumentDownload } from 'react-icons/hi';
import { setOrderStatus } from '../services/OrderService';

export const OrderToolbar: FC<{ showExport: boolean, filteredData: any, getData: () => void }> = ({ showExport, filteredData, getData }) => {
    const [csvData, setCsvData] = useState<any>('');

    useEffect(() => {
        if (filteredData) {
            const result = filteredData.map((order: any) => {
                const prod = order.products.map((item: any) => `${item.productName} - ${item.quantity} - ${item.price}`)
                const productList = prod.toString();
                const { products, createTimestamp, updateTimestamp, orderDate, ...newOrds } = order
                return { ...newOrds, orderDate: moment(order.orderDate, 'DD-MM-YYYY').format('DD-MM-YYYY'), productList }
            });
            setCsvData(result);
        }
    }, [filteredData])

    const handleExport = async () => {
        const orderIDList = filteredData.map((item: any) => item.id);
        await setOrderStatus(orderIDList);
        getData();
    }

    return (
        <div className='p-6'>
            <div className="flex  justify-between">
                <div className='text-xl'>Orders</div>
                <GridToolbarContainer>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    {
                        showExport &&
                        <CSVLink data={csvData} filename={`Orders-${moment().format('DD-MM-YY')}.csv`} onClick={handleExport}>
                            <Button size="small" color="primary" startIcon={<HiOutlineDocumentDownload />} >Export CSV</Button>
                        </CSVLink>
                    }
                    {/* {selectedID.length > 0 && <Button size="small" color="primary" startIcon={<FiTrash2 />} onClick={() => setShowDelete(true)}>Delete Product(s)</Button>} */}
                </GridToolbarContainer>

            </div>
        </div>
    )
}
