export const PRODUCT_INITIAL_VAL = {
    productCode: '',
    productName: '',
    description: '',
    size: [],
    images: null,
    category: '',
    costPrice: 0,
    sellingPrice: 0,
    oldPrice: 0,
    deliveryCharge: 0,
    quantity: 0,
    status: '',
}