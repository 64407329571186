import axios from 'axios';
import imageCompression from 'browser-image-compression';

// const storage = firestorage();

const baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:3200/api/products/uploadToS3';


export const UploadFiles = async (files: any, productCode: string) => {
    // let progress, url, error;
    console.log('Inside Upload file function');

    const URL: String[] = [];
    if (files) {
        for (let i = 0; i < files.length; i++) {

            const fileName = `${productCode}_ID${i + 1}${files[i].name.substring(files[i].name.lastIndexOf('.'))}`;
            const fileType = files[i].type;
            const options = {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            const compressedFile = await imageCompression(files[i], options);
            const { url, signedRequest } = (await axios.post(baseURL, { fileName, fileType })).data
            axios.put(signedRequest, compressedFile, { headers: { 'Content-Type': fileType } });
            // const storageRef = ref(storage, `${productCode}_ID${i + 1}`);
            // const uploader = uploadBytesResumable(storageRef, compressedFile);
            // if ((await uploader).state === 'success') {
            URL.push(url);
            // uploader.on('state_changed', (snap) => {
            //     let progress = (snap.bytesTransferred / fileSize) * 100;
            //     callback({ progress });
            // }, (err) => {
            //     callback({ err });
            // }, async () => {
            //     const url = await getDownloadURL(storageRef);
            //     callback({ url });
            //     // URLs.push(url);
            // });
            // url = URLs;
        }
    }
    return URL
}
