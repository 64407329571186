import { TagInput } from 'evergreen-ui'
import React, { FC } from 'react'
import { BsFillExclamationCircleFill } from 'react-icons/bs'

interface Props {
    sizeList: string[] | undefined;
    formValid: boolean;
    handleInputChange: (data: any) => void
}

export const InputList: FC<Props> = ({ sizeList, formValid, handleInputChange }) => {
    return (
        <div>
            <div className='mb-2 text-sm font-medium'>Size List</div>
            <TagInput
                className={(sizeList?.length === 0 ? 'tag-input-error' : 'tag-input-default')}
                inputProps={{
                    placeholder: 'Add Size list'
                }}
                separator=','
                width='100%'
                values={sizeList}
                onChange={(value: any) => handleInputChange({ target: { 'value': value, name: 'size' } })}
            />
            {!formValid && sizeList?.length === 0 &&
                <>
                    <BsFillExclamationCircleFill display='inline' color='#D14343' size='16px' style={{ marginRight: '8px', display: 'inline' }} />
                    <p style={{ color: '#D14343', lineHeight: '18px', fontSize: '12px', display: 'inline' }}>Invalid Size List</p>

                </>
            }
        </div>
    )
}
