import { Spinner } from 'evergreen-ui'
import React, { FC } from 'react'
import { IconType } from 'react-icons/lib'

export const Tile: FC<{ value: number, label: string, color: string, isLoading: boolean }> = ({ value, label, color, children, isLoading }) => {

    /**
  * Dynamic Colors:
  * bg-red-100
  * bg-green-100
  * bg-yellow-100
  * bg-blue-100
  */

    return (
        <div className='shadow-lg rounded-lg p-5 bg-white'>
            <div className="flex items-center">
                <div >
                    <div className={`p-4 rounded-md text-center bg-${color}-100`}>
                        {children}
                    </div>
                </div>
                <div className="ml-5">
                    {
                        isLoading
                            ? <Spinner size={24} />
                            : <div className='text-4xl font-bold'>{value}</div>
                    }
                    <div className="text-md font-normal text-gray-400">{label}</div>
                </div>
            </div>
        </div>
    )
}
